// 单选框
<template>
  <div>
    <el-radio-group v-bind="$attrs" v-on="$listeners" >
      <el-radio
        v-for="(item, index) of options"
        :label="item.value"
        :key="index"
        >{{ item.label }}</el-radio
      >
    </el-radio-group>
  </div>
</template>
<script>
export default {
  props: {
    options: Array
  }
}
</script>
